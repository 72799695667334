import validate from "/Users/leon/Desktop/multipay_merchant_v2/node_modules/nuxt/dist/pages/runtime/validate.js";
import menu_45auth_45global from "/Users/leon/Desktop/multipay_merchant_v2/middleware/menuAuth.global.js";
import manifest_45route_45rule from "/Users/leon/Desktop/multipay_merchant_v2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  menu_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/Users/leon/Desktop/multipay_merchant_v2/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}