<script setup>
const colorMode = useColorMode();
const color = computed(() =>
  colorMode.value === "dark" ? "#111827" : "white"
);

useHead({
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: color },
  ],
  link: [{ rel: "icon", href: "/favicon.ico" }],
  htmlAttrs: {
    lang: "en",
  },
});

function setDebugger() {
  function doCheck(a) {
    if (("" + a / a)["length"] !== 1 || a % 20 === 0) {
      (function () {})["constructor"]("debugger")();
    } else {
      (function () {})["constructor"]("debugger")();
    }
    doCheck(++a);
  }
  try {
    doCheck(0);
  } catch (err) {}
}
onMounted(() => {
  // setInterval(() => {
  //   setDebugger();
  // }, 1000);
});

const title = "Multipay Merchant BO";
const description = "Multipay Merchant BO";

useSeoMeta({
  title,
  description,
  ogTitle: title,
  ogDescription: description,
});
</script>

<template>
  <div>
    <NuxtLoadingIndicator />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <UNotifications />
    <UModals />
  </div>
</template>
