import { default as deposit_45detailHHr555C5exMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/account/deposit-detail.vue?macro=true";
import { default as frozen_45balances2kiJmboZIMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/account/frozen-balance.vue?macro=true";
import { default as merchant_45statementqOM2ZvofIUMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/account/merchant-statement.vue?macro=true";
import { default as transaction_45recordLjXjTbBwYXMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/account/transaction-record.vue?macro=true";
import { default as commissiongZI0ZSC9HkMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/agent/commission.vue?macro=true";
import { default as indexyFu4OITubJMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/agent/index.vue?macro=true";
import { default as orderp6qOM1k0dKMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/agent/order.vue?macro=true";
import { default as payoutMINbjqcKcAMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/agent/payout.vue?macro=true";
import { default as ratehHUDQova01Meta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/agent/rate.vue?macro=true";
import { default as impersonateOELdrDa539Meta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/auth/impersonate.vue?macro=true";
import { default as loginl33u2IgqiKMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/auth/login.vue?macro=true";
import { default as api_45informationCtnDUFv7FhMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/channel/api-information.vue?macro=true";
import { default as rateGW415vwBOgMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/channel/rate.vue?macro=true";
import { default as indexG1AOfUvzloMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/index.vue?macro=true";
import { default as index5rGSYpghpJMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/order/index.vue?macro=true";
import { default as manual_45reissueQ9xga3tksVMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/order/manual-reissue.vue?macro=true";
import { default as payoutSTtqdOhXLeMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/order/payout.vue?macro=true";
import { default as google_45authenticationnOL5zaUuqqMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/profile/google-authentication.vue?macro=true";
import { default as indexENKbylt50sMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/profile/index.vue?macro=true";
import { default as login_45recordsjWQYoba0LMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/profile/login-record.vue?macro=true";
import { default as my_45cashiercD6gn1eYpiMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/profile/my-cashier.vue?macro=true";
import { default as indexbxe9zdMeo3Meta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/settlement/index.vue?macro=true";
import { default as statementLHymrM9dOpMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/settlement/statement.vue?macro=true";
import { default as role_45managementyesSVoYMm3Meta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/subaccount/role-management.vue?macro=true";
import { default as subaccount_45managementV93vg5Y3wBMeta } from "/Users/leon/Desktop/multipay_merchant_v2/pages/subaccount/subaccount-management.vue?macro=true";
export default [
  {
    name: "account-deposit-detail___en",
    path: "/account/deposit-detail",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/account/deposit-detail.vue").then(m => m.default || m)
  },
  {
    name: "account-deposit-detail___zh",
    path: "/zh/account/deposit-detail",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/account/deposit-detail.vue").then(m => m.default || m)
  },
  {
    name: "account-frozen-balance___en",
    path: "/account/frozen-balance",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/account/frozen-balance.vue").then(m => m.default || m)
  },
  {
    name: "account-frozen-balance___zh",
    path: "/zh/account/frozen-balance",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/account/frozen-balance.vue").then(m => m.default || m)
  },
  {
    name: "account-merchant-statement___en",
    path: "/account/merchant-statement",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/account/merchant-statement.vue").then(m => m.default || m)
  },
  {
    name: "account-merchant-statement___zh",
    path: "/zh/account/merchant-statement",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/account/merchant-statement.vue").then(m => m.default || m)
  },
  {
    name: "account-transaction-record___en",
    path: "/account/transaction-record",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/account/transaction-record.vue").then(m => m.default || m)
  },
  {
    name: "account-transaction-record___zh",
    path: "/zh/account/transaction-record",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/account/transaction-record.vue").then(m => m.default || m)
  },
  {
    name: "agent-commission___en",
    path: "/agent/commission",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/agent/commission.vue").then(m => m.default || m)
  },
  {
    name: "agent-commission___zh",
    path: "/zh/agent/commission",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/agent/commission.vue").then(m => m.default || m)
  },
  {
    name: "agent___en",
    path: "/agent",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/agent/index.vue").then(m => m.default || m)
  },
  {
    name: "agent___zh",
    path: "/zh/agent",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/agent/index.vue").then(m => m.default || m)
  },
  {
    name: "agent-order___en",
    path: "/agent/order",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/agent/order.vue").then(m => m.default || m)
  },
  {
    name: "agent-order___zh",
    path: "/zh/agent/order",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/agent/order.vue").then(m => m.default || m)
  },
  {
    name: "agent-payout___en",
    path: "/agent/payout",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/agent/payout.vue").then(m => m.default || m)
  },
  {
    name: "agent-payout___zh",
    path: "/zh/agent/payout",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/agent/payout.vue").then(m => m.default || m)
  },
  {
    name: "agent-rate___en",
    path: "/agent/rate",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/agent/rate.vue").then(m => m.default || m)
  },
  {
    name: "agent-rate___zh",
    path: "/zh/agent/rate",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/agent/rate.vue").then(m => m.default || m)
  },
  {
    name: "auth-impersonate___en",
    path: "/auth/impersonate",
    meta: impersonateOELdrDa539Meta || {},
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/auth/impersonate.vue").then(m => m.default || m)
  },
  {
    name: "auth-impersonate___zh",
    path: "/zh/auth/impersonate",
    meta: impersonateOELdrDa539Meta || {},
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/auth/impersonate.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___en",
    path: "/auth/login",
    meta: loginl33u2IgqiKMeta || {},
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___zh",
    path: "/zh/auth/login",
    meta: loginl33u2IgqiKMeta || {},
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "channel-api-information___en",
    path: "/channel/api-information",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/channel/api-information.vue").then(m => m.default || m)
  },
  {
    name: "channel-api-information___zh",
    path: "/zh/channel/api-information",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/channel/api-information.vue").then(m => m.default || m)
  },
  {
    name: "channel-rate___en",
    path: "/channel/rate",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/channel/rate.vue").then(m => m.default || m)
  },
  {
    name: "channel-rate___zh",
    path: "/zh/channel/rate",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/channel/rate.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___zh",
    path: "/zh",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "order___en",
    path: "/order",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: "order___zh",
    path: "/zh/order",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: "order-manual-reissue___en",
    path: "/order/manual-reissue",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/order/manual-reissue.vue").then(m => m.default || m)
  },
  {
    name: "order-manual-reissue___zh",
    path: "/zh/order/manual-reissue",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/order/manual-reissue.vue").then(m => m.default || m)
  },
  {
    name: "order-payout___en",
    path: "/order/payout",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/order/payout.vue").then(m => m.default || m)
  },
  {
    name: "order-payout___zh",
    path: "/zh/order/payout",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/order/payout.vue").then(m => m.default || m)
  },
  {
    name: "profile-google-authentication___en",
    path: "/profile/google-authentication",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/profile/google-authentication.vue").then(m => m.default || m)
  },
  {
    name: "profile-google-authentication___zh",
    path: "/zh/profile/google-authentication",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/profile/google-authentication.vue").then(m => m.default || m)
  },
  {
    name: "profile___en",
    path: "/profile",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile___zh",
    path: "/zh/profile",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-login-record___en",
    path: "/profile/login-record",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/profile/login-record.vue").then(m => m.default || m)
  },
  {
    name: "profile-login-record___zh",
    path: "/zh/profile/login-record",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/profile/login-record.vue").then(m => m.default || m)
  },
  {
    name: "profile-my-cashier___en",
    path: "/profile/my-cashier",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/profile/my-cashier.vue").then(m => m.default || m)
  },
  {
    name: "profile-my-cashier___zh",
    path: "/zh/profile/my-cashier",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/profile/my-cashier.vue").then(m => m.default || m)
  },
  {
    name: "settlement___en",
    path: "/settlement",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/settlement/index.vue").then(m => m.default || m)
  },
  {
    name: "settlement___zh",
    path: "/zh/settlement",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/settlement/index.vue").then(m => m.default || m)
  },
  {
    name: "settlement-statement___en",
    path: "/settlement/statement",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/settlement/statement.vue").then(m => m.default || m)
  },
  {
    name: "settlement-statement___zh",
    path: "/zh/settlement/statement",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/settlement/statement.vue").then(m => m.default || m)
  },
  {
    name: "subaccount-role-management___en",
    path: "/subaccount/role-management",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/subaccount/role-management.vue").then(m => m.default || m)
  },
  {
    name: "subaccount-role-management___zh",
    path: "/zh/subaccount/role-management",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/subaccount/role-management.vue").then(m => m.default || m)
  },
  {
    name: "subaccount-subaccount-management___en",
    path: "/subaccount/subaccount-management",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/subaccount/subaccount-management.vue").then(m => m.default || m)
  },
  {
    name: "subaccount-subaccount-management___zh",
    path: "/zh/subaccount/subaccount-management",
    component: () => import("/Users/leon/Desktop/multipay_merchant_v2/pages/subaccount/subaccount-management.vue").then(m => m.default || m)
  }
]