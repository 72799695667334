export default defineNuxtRouteMiddleware(async (to, from) => {
    const { data: userSessionData } = useAuth();
    const menuData = useMenuItems();

    const currentRoute = to.path;
    if (currentRoute === "/auth/impersonate") return;

    if (userSessionData.value) {
        if (menuData.value.length === 0) {
            const data = await $fetch("/api/account/menudata", {
                method: "POST",
                body: JSON.stringify({
                    user: userSessionData.value.user,
                }),
            });
            menuData.value = data;
        }

        // if (currentRoute === "/") return;
        const menuPermission = [];
        menuData.value.forEach((menu) => {
            if (menu.can_read === true) {
                menuPermission.push(menu.route);
            }
        });

        const childrenMenuPermission = [];
        menuData.value.forEach((menu) => {
            menu.children.forEach((child) => {
                if (child.can_read === true) {
                    childrenMenuPermission.push(child.route);
                    childrenMenuPermission.push("/zh" + child.route)
                }
            });
        });

        if (
            !menuPermission.includes(currentRoute) &&
            !childrenMenuPermission.flat().includes(currentRoute)
        ) {
            //previous link
            //   return navigateTo(menuPermission[0]);
        }
    }
});
